<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
.order-locked:not(.ag-row-selected) {
  background-color: #d3d3d3;
}
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title>
            <v-icon>{{ isCafereoUser ? "mdi-book-search" : "mdi-note-search" }}</v-icon
            >フォーキャスト一覧
          </v-app-bar-title>
          <v-spacer></v-spacer>
          <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
          <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
            <search-conditions @search="onBtnSearch" max-height="100%">
              <v-text-field
                v-if="isCafereoUser"
                v-model="searchModel.corporationName"
                label="法人名"
                dense
                :rules="[rules.maxLength(150)]"
              ></v-text-field>
              <v-text-field
                v-if="isCafereoUser"
                v-model="searchModel.customerName"
                label="取引先名"
                dense
                :rules="[rules.maxLength(150)]"
              ></v-text-field>
              <v-text-field
                v-model="searchModel.productName"
                label="商品名"
                dense
                :rules="[rules.maxLength(60)]"
              ></v-text-field>
              <v-text-field
                v-model="searchModel.title"
                label="タイトル"
                dense
                :rules="[rules.maxLength(30)]"
              ></v-text-field>
              <cafereo-user-field
                ref="customerStaff"
                label="担当者"
                :value="defaultCustomerStaffr"
                v-model="searchModel.customerStaff"
                v-if="isCafereoUser"
                :attach="false"
              ></cafereo-user-field>
              <dp-date-picker
                v-model="searchModel.orderDateFrom"
                type="date"
                label="発注日From"
                :rules="[rules.orderedFrom]"
                dense
                v-if="isVendorUser"
              ></dp-date-picker>
              <dp-date-picker
                v-model="searchModel.orderDateTo"
                type="date"
                label="発注日To"
                :rules="[rules.orderedTo]"
                dense
                v-if="isVendorUser"
              ></dp-date-picker>
              <v-text-field
                v-model="searchModel.salesTeam"
                label="担当チーム"
                v-if="isCafereoUser"
                dense
                :rules="[rules.maxLength(150)]"
              ></v-text-field>
              <v-textarea
                v-if="!isCafereoUser"
                v-model="searchModel.jancodeList"
                rows="1"
                :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                label="JANコード"
                dense
              ></v-textarea>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-menu-button
            v-if="allowedAction(['C020302', 'C020303', 'V020302', 'V020303'])"
            text="一括割当"
            icon="mdi-database-import"
          >
            <v-list dense>
              <v-list-item
                v-if="(isCafereoUser && allowedAction(['C020302'])) || (!isCafereoUser && allowedAction(['V020302']))"
                :disabled="!enabledImportOrder"
                @click="onImportClick('order')"
                dense
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-cart</v-icon></v-list-item-avatar>
                <v-list-item-title>{{ isCafereoUser ? "受注指定" : "発注指定" }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="(isCafereoUser && allowedAction(['C020303'])) || (!isCafereoUser && allowedAction(['V020303']))"
                :disabled="!enabledImportShop"
                @click="onImportClick('shop')"
                dense
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-domain</v-icon></v-list-item-avatar>
                <v-list-item-title>店舗指定</v-list-item-title>
              </v-list-item>
            </v-list>
          </tooltip-icon-menu-button>
          <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
            <forecast-assign
              :mode="importModel.mode"
              :orderId="importModel.orderId"
              :entryCorporationType="entryCorporationType"
              :lastUpdateDatetime="importModel.lastUpdateDatetime"
              @complete="onImportComplete"
              @expand="importDialog.width = $event ? '100%' : '800px'"
              v-if="importDialog.show"
            ></forecast-assign>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="(isCafereoUser && allowedAction(['C020304'])) || (!isCafereoUser && allowedAction(['V020304']))"
            icon="mdi-set-split"
            @click="onAssignClick()"
            :disabled="!enabledAssign"
            >割当</tooltip-icon-button
          >
          <v-dialog v-model="assignDialog" max-width="800px" persistent scrollable>
            <order-split
              :assign="true"
              :orderModel="selectedRow"
              @cancel="onAssignCancel"
              @created="onAssignSubmit"
              v-if="assignDialog"
            ></order-split>
          </v-dialog>
          <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C020306'])"
            :disabled="!enabledInsurefixCancel"
            icon="mdi-database-edit"
            @click="onInsureFixCancelClick"
            >確保付け解除</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-download" @click="onExportClick" :disabled="!enabledCsvExport"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <confirm-grid-dialog
      ref="insureFixCancelConfirmGrid"
      width="1100px"
      height="350px"
      icon="mdi-database-edit"
      title="確保付け解除"
      :autoClose="false"
      :columns="cafereoColumns"
    ></confirm-grid-dialog>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <v-row>
          <v-col :cols="isCafereoUser ? '2' : '3'">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                {{ this.isCafereoUser ? "受注数合計" : "発注数量合計" }}：{{ this.orderQuantityTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :cols="isCafereoUser ? '2' : '3'">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                {{ this.isCafereoUser ? "受注合計金額" : "入荷金額合計" }}：{{ this.orderPriceTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="isCafereoUser">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                税込受注合計金額：{{ this.orderPriceTaxTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="isCafereoUser">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                粗利合計金額：{{ this.grossPriceTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="isCafereoUser">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4"> 粗利率：{{ this.grossRate.toFixed(1) }}% </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="orderRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
          @filter-changed="calcTotalItem"
          rowSelection="multiple"
        ></ag-grid-vue>
      </v-col>
      <v-col v-if="shownInfo" id="ForecastInfos" style="flex-basis: auto; display: flex" :cols="infoMaximum ? 12 : 3">
        <v-divider vertical></v-divider>
        <forecast-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :orderId="selectedRow != null ? selectedRow.orderId : null"
          :order="selectedRow != null ? selectedRow : null"
        ></forecast-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  NumericDP2Column,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { LockedCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import ProductTypes from "../../consts/ProductTypes";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import VendorProductStatus from "../../consts/productStatus/VendorProductStatus";
import OrderTypes from "../../consts/OrderTypes";
import CafereoOrderStatuses from "../../consts/orderStatus/CafereoOrderStatuses";
import VendorOrderStatuses from "../../consts/orderStatus/VendorOrderStatuses";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconMenuButton from "../../components/common/TooltipIconMenuButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import OrderSplit from "../../components/order/OrderSplit.vue";
import ForecastAssign from "../../components/order/ForecastAssign.vue";
import ForecastInfos from "../../components/order/ForecastInfos.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import CorporationTypes from "../../consts/CorporationTypes";
import Validation from "../../libs/validation";
import { comma } from "../../filter/NumberFilter";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "ForecastOrderSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconMenuButton,
    TooltipIconToggleButton,
    OrderSplit,
    ForecastAssign,
    ForecastInfos,
    CafereoUserField,
    ConfirmGridDialog,
    ErrorGridDialog,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      gridOptions: {
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpNumericDP2Column: NumericDP2Column,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
        frameworkComponents: {
          dpBooleanFilter: BooleanFilter,
          dpSelectionFilter: SelectionFilter,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
            cellRenderer: LockedCellRenderer({ matcher: "orderLocked" }),
          },
        ],
        rowSelection: "single",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: null,
        suppressColumnVirtualisation: true,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.orderId,
        // rowClassRules: {
        //   "order-locked": (params) => Boolean(params.data.orderLocked),
        // },
      },
      orderRecords: [],
      selectionRows: [],
      searchModel: {
        corporationName: null,
        customerName: null,
        productName: null,
        title: null,
        customerStaff: null,
        orderDateFrom: null,
        orderedTo: null,
      },
      assignDialog: false,
      importDialog: { show: false, width: "800px" },
      importModel: {
        mode: null,
        orderId: "",
        lastUpdateDatetime: null,
      },
      defaultCustomerStaffr: null,
      orderQuantityTotal: 0,
      orderPriceTotal: 0,
      orderPriceTaxTotal: 0,
      lowerPriceTotal: 0,
      grossPriceTotal: 0,
      grossRate: 0,
      cafereoColumns: [
        { headerName: "受注ID", field: "orderId", pinned: "left" },
        { headerName: "発注日", field: "orderDate", type: "dpDateColumn" },
        { headerName: "指示書NO", field: "directionNumber" },
        {
          headerName: "受注ステータス",
          field: "orderStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoOrderStatuses.all() },
          valueGetter: (params) => CafereoOrderStatuses.of(params.data.orderStatus),
        },
        {
          headerName: "受注区分",
          field: "orderType",
          filter: "dpSelectionFilter",
          filterParams: { options: OrderTypes.all() },
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        { headerName: "専用発注", field: "dedicatedOrderFlg", type: "dpCheckmarkColumn" },
        { headerName: "社店CD", field: "customerCode" },
        { headerName: "法人名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName", filterParams: IncludeFilter },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        { headerName: "商品説明", field: "productDescription" },
        {
          headerName: "商品区分",
          field: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        { headerName: "発注単位", field: "orderUnit", filter: "agNumberColumnFilter" },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        { headerName: "受注数", field: "orderQuantity", filter: "agNumberColumnFilter" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
        },
        { headerName: "商品単価（仕切値）", field: "unitPrice", type: "dpNumericColumn" },
        { headerName: "上代合計", field: "retailPriceTotal", type: "dpNumericColumn" },
        { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "メーカー名", field: "supplierName" },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        { headerName: "仕入価格", field: "purchasePrice", type: "dpNumericColumn" },
        { headerName: "粗利", field: "grossProfit", type: "dpNumericColumn" },
        { headerName: "下代金額合計", field: "lowerPriceTotal", type: "dpNumericColumn" },
        { headerName: "発注締日", field: "orderCloseDate", type: "dpDateColumn" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "案件番号", field: "propositionNumber" },
        { headerName: "発注ID", field: "purchaseId" },
        { headerName: "出荷予定日", field: "shipmentPlanDate", type: "dpDateColumn" },
        { headerName: "納品予定日", field: "deliveryPlanDate", type: "dpDateColumn" },
        { headerName: "確保倉庫", field: "insureStorageName" },
        { headerName: "伝票備考", field: "slipRemarks" },
        { headerName: "入荷予定日", field: "arrivalPlanDate" },
        { headerName: "確保数", field: "insureQuantity", type: "dpNumericColumn" },
        { headerName: "営業担当", field: "salesStaff" },
        { headerName: "アシスタント", field: "salesAssistant" },
        { headerName: "担当チーム", field: "salesTeam" },
        { headerName: "備考", field: "remarks" },
        { headerName: "社内備考", field: "cafereoRemarks" },
        { headerName: "一括取込番号", field: "importNumber" },
        { headerName: "注残数", field: "backOrderQuantity", type: "dpNumericColumn" },
        { headerName: "承認者", field: "approvalUser" },
        { headerName: "承認日時", field: "approvalDatetime", type: "dpDateColumn" },
        { headerName: "掛率訂正承認者", field: "rateApprovalUser" },
        { headerName: "掛率訂正承認日時", field: "rateApprovalDatetime", type: "dpDateColumn" },
        { headerName: "販売店承認者", field: "vendorApprovalUser" },
        { headerName: "販売店承認日時", field: "vendorApprovalDatetime", type: "dpDateColumn" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
        { headerName: "ロック", field: "orderLocked", hide: true },
      ],
      vendorColumns: [
        { headerName: "発注ID", field: "orderId", pinned: "left" },
        { headerName: "発注日", field: "orderDate", type: "dpDateColumn" },
        {
          headerName: "発注ステータス",
          field: "orderStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorOrderStatuses.all() },
          valueGetter: (params) => VendorOrderStatuses.of(params.data.orderStatus),
        },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorProductStatus.all() },
          valueGetter: (params) => VendorProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "発注区分",
          field: "orderType",
          filter: "dpSelectionFilter",
          filterParams: { options: OrderTypes.all() },
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        { headerName: "発売元", field: "supplierName" },
        { headerName: "販売元", field: "makerName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
        },
        { headerName: "発注数", field: "orderQuantity", type: "dpNumericColumn" },
        {
          headerName: "卸単価",
          field: "unitPrice",
          type: "dpNumericDP2Column",
        },
        { headerName: "卸単価合計", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "発売日", field: "releaseDate" },
      ],
      cafereoDetailColumns: [
        { headerName: "受注ID", field: "orderId", pinned: "left" },
        { headerName: "発注日", field: "orderDate", type: "dpFullDateColumn" },
        { headerName: "指示書NO", field: "directionNumber" },
        {
          headerName: "受注ステータス",
          field: "orderStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoOrderStatuses.all() },
          valueGetter: (params) => CafereoOrderStatuses.of(params.data.orderStatus),
        },
        {
          headerName: "受注区分",
          field: "orderType",
          filter: "dpSelectionFilter",
          filterParams: { options: OrderTypes.all() },
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        { headerName: "専用発注", field: "dedicatedOrderFlg", type: "dpCheckmarkColumn" },
        { headerName: "社店CD", field: "customerCode" },
        { headerName: "法人名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品説明", field: "productDescription" },
        {
          headerName: "商品区分",
          field: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        { headerName: "発注単位", field: "orderUnit" },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        { headerName: "受注数", field: "orderQuantity" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        { headerName: "卸掛率", field: "wholesaleRate", type: "dpPercentColumn" },
        { headerName: "商品単価（仕切値）", field: "unitPrice", type: "dpNumericColumn" },
        { headerName: "上代合計", field: "retailPriceTotal", type: "dpNumericColumn" },
        { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "メーカー名", field: "supplierName" },
        { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
        { headerName: "仕入価格", field: "purchasePrice", type: "dpNumericColumn" },
        { headerName: "粗利", field: "grossProfit", type: "dpNumericColumn" },
        { headerName: "下代金額合計", field: "lowerPriceTotal", type: "dpNumericColumn" },
        { headerName: "発注締日", field: "orderCloseDate", type: "dpFullDateColumn" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "案件番号", field: "propositionNumber" },
        { headerName: "発注ID", field: "purchaseId" },
        { headerName: "出荷予定日", field: "shipmentPlanDate", type: "dpFullDateColumn" },
        { headerName: "納品予定日", field: "deliveryPlanDate", type: "dpFullDateColumn" },
        { headerName: "確保倉庫", field: "insureStorageName" },
        { headerName: "伝票備考", field: "slipRemarks" },
        { headerName: "入荷予定日", field: "arrivalPlanDate" },
        { headerName: "確保数", field: "insureQuantity", type: "dpNumericColumn" },
        { headerName: "営業担当", field: "salesStaff" },
        { headerName: "アシスタント", field: "salesAssistant" },
        { headerName: "担当チーム", field: "salesTeam" },
        { headerName: "備考", field: "remarks" },
        { headerName: "社内備考", field: "cafereoRemarks" },
        { headerName: "一括取込番号", field: "importNumber" },
        { headerName: "注残数", field: "backOrderQuantity", type: "dpNumericColumn" },
        { headerName: "承認者", field: "approvalUser" },
        { headerName: "承認日時", field: "approvalDatetime", type: "dpFullDateColumn" },
        { headerName: "掛率訂正承認者", field: "rateApprovalUser" },
        { headerName: "掛率訂正承認日時", field: "rateApprovalDatetime", type: "dpFullDateColumn" },
        { headerName: "販売店承認者", field: "vendorApprovalUser" },
        { headerName: "販売店承認日時", field: "vendorApprovalDatetime", type: "dpFullDateColumn" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpFullDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpFullDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      vendorDetailColumns: [
        { headerName: "発注ID", field: "orderId" },
        { headerName: "発注日", field: "orderDate", type: "dpDateColumn" },
        {
          headerName: "発注ステータス",
          field: "orderStatus",
          valueGetter: (params) => VendorOrderStatuses.of(params.data.orderStatus),
        },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          valueGetter: (params) => VendorProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "発注区分",
          field: "orderType",
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        { headerName: "発売元", field: "supplierName" },
        { headerName: "販売元", field: "makerName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
        },
        { headerName: "発注数", field: "orderQuantity", type: "dpNumericColumn" },
        {
          headerName: "卸単価",
          field: "unitPrice",
          type: "dpNumericDP2Column",
        },
        { headerName: "卸単価合計", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "発売日", field: "releaseDate" },
      ],
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        orderedFrom: (value) => this.orderedFromRules(value),
        orderedTo: (value) => this.orderedToRules(value),
        isJancodeSearchList: Validation.isJancodeSearchList,
      },
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
    if (this.isCafereoUser) this.gridOptions.columnDefs.push(...this.cafereoColumns);
    if (this.isVendorUser) this.gridOptions.columnDefs.push(...this.vendorColumns);
    this.defaultCustomerStaffr = this.$store.getters["security/loggedInUser"].loginId;
  },
  mounted() {
    if (this.isVendorUser) {
      this.searchModel.orderDateFrom = moment().add(-1, "months").format("YYYY-MM-DD");
      this.onSearchClick();
    }
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      var detailColumns = this.isCafereoUser ? this.cafereoDetailColumns : this.vendorDetailColumns;
      return getDisplayDetails2(this.selectedRow.orderId, detailColumns, this.gridOptions.api);
    },
    enabledImportOrder() {
      if (this.isCafereoUser) {
        return (
          this.allowedAction(["C020302"]) &&
          this.selectedRow &&
          this.selectedRow.orderStatus < 7 &&
          this.selectedRow.orderLocked == false
        );
      } else {
        return (
          this.allowedAction(["V020302"]) &&
          this.selectedRow &&
          this.selectedRow.orderStatus > 2 &&
          this.selectedRow.orderStatus < 7 &&
          this.selectedRow.orderLocked == false
        );
      }
    },
    enabledImportShop() {
      if (this.isCafereoUser) {
        return this.allowedAction(["C020303"]);
      } else {
        return this.allowedAction(["V020303"]);
      }
    },
    enabledAssign() {
      if (this.isCafereoUser) {
        return (
          this.allowedAction(["C020304"]) &&
          this.selectedRow &&
          this.selectedRow.orderStatus < 7 &&
          this.selectedRow.orderLocked == false
        );
      } else {
        return (
          this.allowedAction(["V020304"]) &&
          this.selectedRow &&
          this.selectedRow.orderStatus > 2 &&
          this.selectedRow.orderStatus < 7 &&
          this.selectedRow.orderLocked == false
        );
      }
    },
    enabledCsvExport() {
      if (this.isCafereoUser) {
        return this.allowedAction(["C020305"]);
      } else {
        return this.allowedAction(["V020305"]);
      }
    },
    entryCorporationType() {
      if (this.isCafereoUser) {
        return CorporationTypes.CAFEREO;
      } else if (this.isVendorUser) {
        return CorporationTypes.VENDOR;
      }
      return null;
    },
    enabledInsurefixCancel() {
      if (this.selectionRows?.length == 0) return false;
      return this.selectionRows.every((row) => row.orderStatus == CafereoOrderStatuses.SECURED);
    },
  },
  filters: { comma },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        var params = null;
        if (this.isCafereoUser) {
          params = {
            corporationName: this.searchModel.corporationName,
            customerName: this.searchModel.customerName,
            productName: this.searchModel.productName,
            title: this.searchModel.title,
            customerStaff: this.searchModel.customerStaff,
            salesTeam: this.searchModel.salesTeam,
          };
        } else {
          params = {
            productName: this.searchModel.productName,
            title: this.searchModel.title,
            orderDateFrom: this.searchModel.orderDateFrom,
            orderDateTo: this.searchModel.orderDateTo,
            jancode: this.searchModel.jancodeList
              ? this.searchModel.jancodeList
                  .replaceAll(/\n+/g, " ")
                  .split(" ")
                  .filter((n) => n)
              : null,
          };
        }
        const response = await this.$store.dispatch(
          this.isCafereoUser ? "forcast/search" : "forcast/postSearch",
          params
        );
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "フォーキャスト一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.orders).length === 0) {
          this.$dialog.warning({
            title: "フォーキャスト一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.orders);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
      } catch (error) {
        console.error("ForecastSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onAssignClick() {
      this.assignDialog = true;
    },
    onAssignSubmit() {
      this.assignDialog = false;
      this.onSearchClick();
    },
    onAssignCancel() {
      this.assignDialog = false;
    },
    onImportClick(mode) {
      const model = { mode };
      if (mode === "order") {
        model.orderId = this.selectedRow.orderId;
        model.lastUpdateDatetime = this.selectedRow.updateDatetime;
      }
      this.importModel = model;
      this.importDialog.show = true;
    },
    onImportComplete(canceled) {
      this.importDialog.show = false;
      if (!canceled) this.onSearchClick();
    },
    onExportClick() {
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      var params = {
        fileName: "フォーキャスト一覧.csv",
        allColumns: false,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      };
      this.gridOptions.api.exportDataAsCsv(params);
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    calcTotalItem() {
      this.orderQuantityTotal = 0;
      this.orderPriceTotal = 0;
      this.orderPriceTaxTotal = 0;
      this.lowerPriceTotal = 0;
      this.grossPriceTotal = 0;
      this.grossRate = 0;
      this.shipQuantityTotal = 0;
      let rowData = [];
      this.gridOptions.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.orderQuantityTotal += row.orderQuantity ? row.orderQuantity : 0;
        this.orderPriceTotal += row.unitPriceTotal ? row.unitPriceTotal : 0;
        if (this.isCafereoUser) {
          this.lowerPriceTotal += row.lowerPriceTotal ? row.lowerPriceTotal : 0;
          this.grossPriceTotal += row.grossProfit ? row.grossProfit * row.orderQuantity : 0;
          this.orderPriceTaxTotal +=
            row.unitPriceTotal && row.consumptionTaxRate
              ? row.unitPriceTotal * ((100 + row.consumptionTaxRate) / 100)
              : 0;
        }
      }
      if (this.isCafereoUser) {
        this.grossRate = Math.round((this.grossPriceTotal / this.lowerPriceTotal) * 10) / 10;
        this.orderPriceTaxTotal = Math.round(this.orderPriceTaxTotal);
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    orderedFromRules(value) {
      if (value == null || this.searchModel.orderDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.orderDateTo)) return "発注日Toより前の日付を指定してください";
      return true;
    },
    orderedToRules(value) {
      if (value == null || this.searchModel.orderDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.orderDateFrom)) return "発注日Fromより後の日付を指定してください";
      return true;
    },
    onInsureFixCancelClick() {
      this.$refs.insureFixCancelConfirmGrid.open({
        records: this.selectionRows,
        subtitle: `全: ${this.selectionRows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            this.$refs.insureFixCancelConfirmGrid.gridOptions.api.forEachNode((node) => {
              updateModels.push({
                orderId: node.data.orderId,
              });
            });
            const response = await this.$store.dispatch("insure/fixCancel", { orders: updateModels });
            let error = response.data?.header;
            // 一覧更新レコード
            let updateRows = [];
            let errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      orderId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                  this.selectionRows.forEach((row) => {
                    let isError = errorRows.some((r) => r.orderId === row.orderId);
                    if (!isError) {
                      row.orderStatus = CafereoOrderStatuses.WAITING;
                      updateRows.push(row);
                    }
                  });
                } else {
                  updateRows = this.selectionRows;
                  updateRows.forEach((row) => {
                    row.orderStatus = CafereoOrderStatuses.WAITING;
                  });
                }

                if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
                // エラー表示
                if (errorRows.length > 0) {
                  // 確認ダイアログから更新完了分のデータを削除する
                  this.$refs.insureFixCancelConfirmGrid.gridOptions.api.applyTransaction({ remove: updateRows });
                  this.$refs.errorGrid.open({
                    title: "確保付け解除",
                    columns: [
                      {
                        headerName: "受注ID",
                        field: "orderId",
                        pinned: "left",
                      },
                      { headerName: "エラーメッセージ", field: "errorMessage" },
                    ],
                    records: errorRows,
                  });
                  this.$dialog.notify.error(`確保付け解除処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`受注の確保付けを解除しました`, { timeout: 2300 });
                  this.$refs.insureFixCancelConfirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("ForecastSearch::onInsureFixCancelClick", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
  },
};
</script>
