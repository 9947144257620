<template>
  <v-container fluid style="height: 100%">
    <v-row no-gutters>
      <v-col>
        <v-btn icon v-if="infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-minimize</v-icon></v-btn
        >
        <v-btn icon v-if="!infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-maximize</v-icon></v-btn
        >
        <v-tabs v-model="tab" centered grow class="mt-2 mb-2" @change="onActiveTabChanged" hide-slider>
          <v-tab href="#detail">{{ isCafereo ? "販売店返品詳細" : "返品詳細" }}</v-tab>
          <v-tab href="#orderDetail">{{ isCafereo ? "受注" : "発注" }}詳細</v-tab>
          <v-tab href="#history">履歴</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" id="tab-items">
          <v-tab-item id="detail">
            <order-return-details :style="detaleStyle" :details="details"></order-return-details>
          </v-tab-item>
          <v-tab-item id="orderDetail">
            <order-details :style="detaleStyle" :details="orderDetails"></order-details>
          </v-tab-item>
          <v-tab-item id="history">
            <order-return-history :orderReturnId="orderReturnId"></order-return-history>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderReturnDetails from "./OrderReturnDetails.vue";
import OrderReturnHistory from "./OrderReturnHistory.vue";
import OrderDetails from "./../order/OrderDetails.vue";
import { getDisplayDetailsValue } from "../../models/ag-grid/helpers";
import { Cafereo as CafereoColumnDefs, Vendor as VendorColumnDefs } from "../../consts/columns/OrderClumns";

export default {
  name: "OrderReturnInfos",
  props: ["details", "orderReturnId", "orderId", "isCafereo"],
  data: () => ({
    tab: null,
    items: ["1", "2"],
    infoMaximum: false,
    orderDetails: [],
    detaleStyle: {
      height: window.innerHeight - 260 + "px",
      overflow: "scroll",
    },
  }),
  components: {
    OrderReturnDetails,
    OrderReturnHistory,
    OrderDetails,
  },
  watch: {
    orderId() {
      this.onActiveTabChanged();
    },
  },
  methods: {
    onInfoMaximum: function () {
      this.infoMaximum = !this.infoMaximum;
      this.$emit("infoMaximum-event");
    },
    async onActiveTabChanged() {
      if (this.tab == "orderDetail") {
        if (this.orderId) {
          try {
            this.loadingOn();
            let searchModel = { orderIdList: [this.orderId], statusSkipFlg: true };
            const response = await this.$store.dispatch("orderReturn/orderSearch", searchModel);

            const culDefs = (this.isCafereo ? CafereoColumnDefs : VendorColumnDefs).filter((column) => {
              return !column.hide;
            });

            this.orderDetails = getDisplayDetailsValue(culDefs, response.data.contents.orders[0]);
          } catch (error) {
            console.error("OrderReturnInfos::onActiveTabChanged", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        } else {
          this.orderDetails = [];
        }
      }
    },
    handleResize: function () {
      this.detaleStyle.height = window.innerHeight - 260 + "px";
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
